<template>
  <div class="flex justify-center">
    <div class="w-80 mt-6">
      <b-field label="Name">
        <b-input
          type="text"
          placeholder="name"
          maxlength="25"
          value="ABC"
          v-model="editUserProfile.name"
        ></b-input>
      </b-field>
      <b-field label="Gender" class="mb-5">
        <b-radio
          v-model="editUserProfile.gender"
          name="gender"
          native-value="Male"
        >
          Male
        </b-radio>
        <b-radio
          v-model="editUserProfile.gender"
          name="gender"
          native-value="Female"
        >
          Female
        </b-radio>
        <b-radio
          v-model="editUserProfile.gender"
          name="gender"
          native-value="Others"
        >
          Others
        </b-radio>
      </b-field>
      <b-field label="Mobile Number">
        <b-input
          type="text"
          placeholder="8754612584"
          maxlength="10"
          v-model="editUserProfile.mobilenumber"
        ></b-input>
      </b-field>
      <b-field label="Email">
        <b-input
          type="email"
          placeholder="@udaanplanet.in"
          maxlength="30"
          v-model="editUserProfile.email"
          disabled
        ></b-input>
      </b-field>
      <div class="pt-3">
        <b-button
          icon-pack="fal"
          icon-left="edit"
          class="btn"
          @click="updateProfile"
          :loading="loading"
          expanded
        >
          Update Profile
        </b-button>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 ">
      <b-message
        type="is-success"
        icon-pack="far"
        class=" w-96"
        has-icon
        v-model="isSuccess"
        :auto-close="autoClose"
      >
        <span class="text-xl pt-5">Profile Updated Successfully</span>
      </b-message>
      <div class="absolute bottom-4 right-4 ">
        <b-message
          type="is-danger"
          icon-pack="far"
          class=" w-96"
          has-icon
          v-model="isDanger"
          :auto-close="autoClose"
        >
          <span class="text-xl pt-5">Profile Not Updated</span>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import * as fb from '../firebase'
import { mapState } from "vuex";
export default {
  name: "Profile",

  data() {
    return {
      isSuccess: false,
      isDanger: false,
      autoClose: true,
      loading: false,
    };
  },
  computed: {
    ...mapState(["editUserProfile"]),
  },
  methods: {
    updateProfile() {
      const user = fb.auth.currentUser
      this.loading = true;
      try {
        console.log("Start TRY END");
        fb.userCollection.doc(user.uid).update({
          name: this.editUserProfile.name,
          gender: this.editUserProfile.gender,
          mobilenumber: this.editUserProfile.mobilenumber,
          email: this.editUserProfile.email,
        });
        console.log("IN TRY END");
        this.isSuccess = true;
      } catch (error) {
        this.isDanger = true;
        this.loading = false;
      }
      console.log("OUT TRY END");
      setInterval(() => {
        this.loading = false;
      }, 800);
    },
  },
};
</script>
